import logo from './logo.svg';
import './App.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Commission from './Commission';
import musicList from './music';
import Marquee from "react-fast-marquee";

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { collection, query, doc, getDocs, getDoc, setDoc, addDoc, getFirestore, updateDoc, where, deleteDoc } from 'firebase/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4CgROTtsZu-akuwHsG8bwHXIV_X674hU",
  authDomain: "genshin-todo-f2b7b.firebaseapp.com",
  projectId: "genshin-todo-f2b7b",
  storageBucket: "genshin-todo-f2b7b.appspot.com",
  messagingSenderId: "355271135183",
  appId: "1:355271135183:web:92f4dd67100807d974ccba",
  measurementId: "G-J6J7TLMJ84"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

const progressImageArray = [
  'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/tier0.png?alt=media&token=6b72e23d-d55d-4c3a-87eb-6a90cfc17ad4',
  'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/tier1.png?alt=media&token=c5d95beb-c6c9-4a68-9fc4-d1faec914d68',
  'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/tier2.png?alt=media&token=a90f12a6-6454-4cd1-9ecc-277c4bc1899c',
  'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/tier3.png?alt=media&token=f29a7cc7-beef-4017-ba58-a60746c5b736',
  'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/tier4.png?alt=media&token=2464c6ae-72b1-4be4-bbd5-c00b47805d01',
  'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/tier5.png?alt=media&token=5cf1bc70-7a6e-4031-bacb-7fc5ec401b1d',
  'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/tier6.png?alt=media&token=9096bcfd-377e-4f33-ad38-b30701e9d84f'
]

function App() {
  const [user] = useAuthState(auth);
  const [existingUserRef, setExistingUserRef] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState('Mondstadt');
  const [showRegionDropdown, setShowRegionDropdown] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isSongPlaying, setIsSongPlaying] = useState(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [userAvatar, setUserAvatar] = useState('https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/Character_Unknown_Thumb.png?alt=media&token=977d2203-ff20-4e30-bbf9-096a0aa5e93d');
  const [existingCommissions, setExistingCommissions] = useState([]);
  const [numCompletedCommissions, setNumCompletedCommissions] = useState(0);
  const [progressBarRender, setProgressBarRender] = useState([]);
  const [showAddCommissionModal, setShowAddCommissionModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState(null);
  const [showCommissionDetails, setShowCommissionDetails] = useState(false);
  const [commisionDetailsTitle, setCommissionDetailsTitle] = useState("");
  const [commissionDetailsNotes, setCommissionDetailsNotes] = useState("");
  const [commissionDetailsId, setCommissionDetailsId] = useState("");
  const [commissionDetailsError, setCommisionsDetailsError] = useState(null);
  const [showSongChangeModal, setShowSongChangeModal] = useState(false);
  const [songGalleryArray, setsongGalleryArray] = useState([]);
  const [showPleaseSignInMessage, setShowPleaseSignInMessage] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const randomSeed = Date.now();

  const [songPath, setSongPath] = useState(musicList[randomSeed%18].path);
  const [songCover, setSongCover] = useState(musicList[randomSeed%18].cover);
  const [songName, setSongName] = useState(musicList[randomSeed%18].name);
  
  const bgDivRef = useRef(null);

  const resetStates = () => {
    setExistingCommissions([]);
    setNumCompletedCommissions(0);
    setExistingUserRef(null);
    const progressOneImage = progressImageArray[0];
    const progressTwoImage = progressImageArray[0];
    const progressThreeImage = progressImageArray[0];
    const progressFourImage = progressImageArray[0];

    const tempProgressBarRender = [];

    tempProgressBarRender.push(
      <img
        className={`commission-progress-icon progress-${0}`}
        src={progressOneImage}
      >
      </img>
    );
    tempProgressBarRender.push(
      <img
        className={`commission-progress-icon progress-${0}`}
        src={progressTwoImage}
      >
      </img>
    );
    tempProgressBarRender.push(
      <img
        className={`commission-progress-icon progress-${0}`}
        src={progressThreeImage}
      >
      </img>
    );
    tempProgressBarRender.push(
      <img
        className={`commission-progress-icon progress-${0}`}
        src={progressFourImage}
      >
      </img>
    );

    setProgressBarRender(tempProgressBarRender);
  }

  const handleRegionChange = () => {
    const bgDiv = bgDivRef.current;
    if (bgDiv) {
      switch (selectedRegion){
        case 'Monstadt':
          bgDiv.style.backgroundImage = "url(https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/backgrounds%2F8f0dea7e-b29a-4bbe-985b-65c156e0b785.jpeg?alt=media&token=6154e054-8b5f-4f46-bc2e-1b22a4f61e47)";
          break;
        case 'Liyue':
          bgDiv.style.backgroundImage = "url(https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/backgrounds%2Fb331bee1-b9b0-44f4-9133-af901fe24ac0.jpeg?alt=media&token=0baec87d-b87c-4920-a65b-48bec12063ff)";
          break;
        case 'Inazuma':
          bgDiv.style.backgroundImage = "url(https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/backgrounds%2Fe5c4ecc8-9422-4846-b159-bc655e790aeb.jpeg?alt=media&token=82ae74ac-84a0-408e-9a73-a212306fe633)";
          break;
        case 'Sumeru':
          bgDiv.style.backgroundImage = "url(https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/backgrounds%2F91fd6b18-e00d-40ba-981d-559f21bb9a23.jpeg?alt=media&token=720f70a1-69f3-4f42-917f-a4cb42e22917)";
          break;
        case 'Fontaine':
          bgDiv.style.backgroundImage = "url(https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/backgrounds%2F9a35acba-0426-4fc6-8c95-791d71f0aeee.jpeg?alt=media&token=a211f254-7642-462b-ac95-abeaf2047818)";
          break;
      }
    }
    return;
  }

  useEffect(() => {
    if (initialLoad) {
      resetStates();
      setInitialLoad(false)
    }
  }, [initialLoad])

  useEffect(() => {
    const tempSongGallery = [];
    musicList.forEach(song => {
      tempSongGallery.push(
        <Container className='song-block' onClick={() => {
          setSongPath(song.path);
          setSongCover(song.cover);
          setSongName(song.name);
          setShowSongChangeModal(false);
        }}>
          <Row style={{width: '50%'}}>
            <img
              src={song.cover}
              className="song-cover-picture"
            ></img>
          </Row>
          <Row className='song-name' style={{width: '50%'}}>
            <span>{song.name}</span>
          </Row>
        </Container>
      );
    });
    setsongGalleryArray(tempSongGallery);
  }, [showSongChangeModal])

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  const toggleAudio = () => {
    setIsSongPlaying(!isSongPlaying);
  }

  const checkUserStatus = async () => {
    const db = getFirestore();

    let isExisting = null;
    const q = query(collection(db, 'users'), where('email', '==', user.email));
    const querySnap = await getDocs(q);
    querySnap.forEach((existingUser) => {
      if (existingUser.data().email === user.email) {
        isExisting = existingUser.id;
        setSelectedRegion(existingUser.data().region);
      }
    });

    if (isExisting) {
      setExistingUserRef(isExisting);
      await updateDoc(doc(db, 'users', isExisting), {
        last_active: firebase.firestore.FieldValue.serverTimestamp()
      });
      const taskRef = collection(db, "users", user.uid, "tasks");
      const tasksSnap = await getDocs(taskRef);
      const sortedByDate = [];
      tasksSnap.forEach(task => {
        sortedByDate.push({
            id: task.id,
            name: task.data().name,
            notes: task.data().notes,
            time: task.data().createdAt.seconds
        })
      });
      sortedByDate.sort((a,b) => b.time - a.time);
      const tempTaskArrayRender = [];
      sortedByDate.forEach(task => [
        tempTaskArrayRender.push(<Commission 
          id={task.id}
          title={task.name}
          notes={task.notes}
          setShowCommissionDetails={setShowCommissionDetails}
          setCommissionDetailsTitle={setCommissionDetailsTitle}
          setCommissionDetailsNotes={setCommissionDetailsNotes}
          setCommissionDetailsId={setCommissionDetailsId}></Commission>)
      ]);
      setExistingCommissions(tempTaskArrayRender);

      const completedTaskRef = collection(db, "users", user.uid, "completedTasks");
      const completedTasksSnap = await getDocs(completedTaskRef);
      const activeCompletedTasks = [];
      const today = new Date();
      completedTasksSnap.forEach(task => {
        const taskCompletedDateMilliseconds = task.data().completedAt.seconds * 1000;
        const taskCompletedData = new Date(taskCompletedDateMilliseconds);
        if (taskCompletedData.getFullYear() === today.getFullYear() &&
        taskCompletedData.getMonth() === today.getMonth() &&
        taskCompletedData.getDate() === today.getDate()) {
          activeCompletedTasks.push(
            {
              name: task.data().name,
              notes: task.data().notes,
              completedAt: taskCompletedData
            }
          );
        }
      });
      // setNumCompletedCommissions(activeCompletedTasks.length);
      let numCompletedCommissions = activeCompletedTasks.length;
      setNumCompletedCommissions(numCompletedCommissions);
      let commissionOneTier = 0;
      let commissionTwoTier = 0;
      let commissionThreeTier = 0;
      let commissionFourTier = 0;

      let counter = 0;
      while (numCompletedCommissions > 0) {
        if (counter === 0) {
          commissionOneTier++;
          counter++;
        } else if (counter === 1) {
          commissionTwoTier++;
          counter++;
        } else if (counter === 2) {
          commissionThreeTier++;
          counter++;
        } else if (counter === 3) {
          commissionFourTier++;
          counter = 0;
        }

        numCompletedCommissions--;
      }

      // console.log(commissionOneTier, commissionTwoTier, commissionThreeTier, commissionFourTier);

      const progressOne = commissionOneTier >= 6 ? 'max' : (commissionOneTier > 0 ? 'mid' : '0');
      const progressTwo = commissionTwoTier >= 6 ? 'max' : (commissionTwoTier > 0 ? 'mid' : '0');
      const progressThree = commissionThreeTier >= 6 ? 'max' : (commissionThreeTier > 0 ? 'mid' : '0');
      const progressFour = commissionFourTier >= 6 ? 'max' : (commissionFourTier > 0 ? 'mid' : '0');

      const progressOneImage = progressImageArray[commissionOneTier];
      const progressTwoImage = progressImageArray[commissionTwoTier];
      const progressThreeImage = progressImageArray[commissionThreeTier];
      const progressFourImage = progressImageArray[commissionFourTier];

      const tempProgressBarRender = [];

      tempProgressBarRender.push(
        <img
          className={`commission-progress-icon progress-${progressOne}`}
          src={progressOneImage}
        >
        </img>
      );
      tempProgressBarRender.push(
        <img
          className={`commission-progress-icon progress-${progressTwo}`}
          src={progressTwoImage}
        >
        </img>
      );
      tempProgressBarRender.push(
        <img
          className={`commission-progress-icon progress-${progressThree}`}
          src={progressThreeImage}
        >
        </img>
      );
      tempProgressBarRender.push(
        <img
          className={`commission-progress-icon progress-${progressFour}`}
          src={progressFourImage}
        >
        </img>
      );

      setProgressBarRender(tempProgressBarRender);
    } else {
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        region: 'Mondstadt',
        user_photo: user.photoURL ? user.photoURL : "https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/Traveler_Icon.jpg?alt=media&token=5e74cd8c-3d84-43bc-b0e2-be4d682000be",
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        last_active: firebase.firestore.FieldValue.serverTimestamp()
      });

      const userRef = doc(db, 'users', user.uid);
      const tasks = collection(userRef, 'tasks');
      await addDoc(tasks, {
          name: 'Example Commission',
          notes: 'Example Commission Notes',
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          isComplete: false,
      });

      const taskRef = collection(db, "users", user.uid, "tasks");
      const tasksSnap = await getDocs(taskRef);
      const sortedByDate = [];
      tasksSnap.forEach(task => {
        sortedByDate.push({
            id: task.id,
            name: task.data().name,
            notes: task.data().notes,
            time: task.data().createdAt.seconds
        })
      });
      sortedByDate.sort((a,b) => b.time - a.time);
      const tempTaskArrayRender = [];
      sortedByDate.forEach(task => [
        tempTaskArrayRender.push(<Commission 
          id={task.id}
          title={task.name}
          notes={task.notes}
          setShowCommissionDetails={setShowCommissionDetails}
          setCommissionDetailsTitle={setCommissionDetailsTitle}
          setCommissionDetailsNotes={setCommissionDetailsNotes}
          setCommissionDetailsId={setCommissionDetailsId}></Commission>)
      ]);
      setExistingCommissions(tempTaskArrayRender);

      const completedTasks = collection(userRef, 'completedTasks');
      // await addDoc(completedTasks, {
      //     name: 'Example Completed Commission',
      //     notes: 'Example Completed Commission notes',
      //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      //     completedAt: firebase.firestore.FieldValue.serverTimestamp(),
      //     isComplete: true,
      // });
    }
  }

  const addNewCommission = async () => {
    let newCommissionName = document.getElementById('new-commission-title').value;
    let newCommissionNotes = document.getElementById('new-commission-notes').value;

    if (!newCommissionName) {
      return {
        status: false,
        message: "Commission title cannot be empty"
      };
    }

    if (!newCommissionNotes) {
      newCommissionNotes = "No Commission Notes"
    }

    if (newCommissionName.trim() === "") {
      return {
        status: false,
        message: "Commission title cannot be empty"
      }
    }

    if (newCommissionNotes.trim() === "") {
      newCommissionNotes = "No Commission Notes"
    }

    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);
    const tasks = collection(userRef, 'tasks');
    await addDoc(tasks, {
      name: newCommissionName,
      notes: newCommissionNotes,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      completedAt: firebase.firestore.FieldValue.serverTimestamp(),
      isComplete: false,
    });

    return {
      status: true,
      message: "New Commission created successfully"
    };
  }

  const completeCommission = async (commissionId) => {
    const db = getFirestore();

    const tasksRef = collection(db, 'users', user.uid, 'tasks');
    const tasksSnap = await getDocs(tasksRef);

    let task;

    tasksSnap.forEach(taskSnap => {
      if (taskSnap.id === commissionId) {
        task = taskSnap;
      }
    });

    const completedTasksRef = collection(db, 'users', user.uid, 'completedTasks');

    if (!task.exists) {
      return {
        status: false,
        message: 'There was an error completing this commission (CODE: AYAKA)'
      }
    }

    try {
      await addDoc(completedTasksRef, {
        name: task.data().name,
        notes: task.data().notes,
        createdAt: task.data().createdAt,
        completedAt: firebase.firestore.FieldValue.serverTimestamp(),
        isComplete: true,
      });
    } catch (err) {
      console.log(err);
      return {
        status: false,
        message: 'There was an error completing this commission (CODE: KEQING)'
      }
    }

    const taskToDeleteRef = doc(db, 'users', user.uid, 'tasks', commissionId);

    try {
      await deleteDoc(taskToDeleteRef);
    } catch (err) {
      return {
        status: false,
        message: 'There was an error completing this commission. It has succesfully been added to your completed commission history (CODE: DEHYA)'
      }
    }

    return {
      status: true,
      message: 'Commission Completed Successfully'
    };
  }

  const abandonCommission = async (commissionId) => {
    const db = getFirestore();
    const taskToDeleteRef = doc(db, 'users', user.uid, 'tasks', commissionId);

    try {
      await deleteDoc(taskToDeleteRef);
    } catch (err) {
      console.log(err);
      return {
        status: false,
        message: 'There was an error abandoning this commission. (CODE: SCARA)'
      }
    }

    return {
      status: true,
      message: 'Commission Abandoned Successfully'
    };
  }

  useEffect(() => {
    if (user) {
      checkUserStatus();
      setUserAvatar(user.photoURL);
    }
  }, [user])

  useEffect(() => {
    var audioPlayer = document.getElementById("audio");
    if (audioPlayer) {
      isSongPlaying ? audioPlayer.play() : audioPlayer.pause();
    }
  }, [isSongPlaying]);

  useEffect(() => {
    var audioPlayer = document.getElementById("audio");
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.load();
      if (isSongPlaying) {
        audioPlayer.play();
      }
    }
  }, [songPath])

  useEffect(() => {
    handleRegionChange();
  }, [selectedRegion]);

  return (
    <div className="main-container" ref={bgDivRef}>
      <audio id="audio" controls loop> 
        <source src={songPath} type="audio/mpeg"></source>
      </audio>
      <div className="book-container">
        <img 
          className="commission-book-background"
          src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/base%20commission%20no%20content%20crop%202.png?alt=media&token=42bd0ecb-548e-4e37-bc59-0640bb2273b6">
        </img>
        <div className="region-dropdown" onClick={() => {setShowRegionDropdown(!showRegionDropdown)}}>
          <Row>
            <Col>
              <span className="region-text">{selectedRegion}</span>
            </Col>
            <Col>
              <img 
                className="dropdown-arrow"
                src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/60995.png?alt=media&token=863e6c33-1de0-4543-83b2-708c86e07de0">
              </img>
            </Col>
          </Row>
        </div>
        <div className="commision-list-container">
          {existingCommissions}
        </div>
        <div className='book-subheader'>
          <span>Complete 4 commissions daily to obtain one free <span className='ayaka'>Ayaka</span></span>
        </div>
        <div className='bonus-rewards-conainer'>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/bonusRewardPouch.png?alt=media&token=dd1d09a2-b877-4fd5-8083-e5f69360d441"
            className='bonus-reward-squares'>
          </img>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/bonusRewardPrimo.png?alt=media&token=0862164a-cf75-44df-a776-3505428cea6f"
            className='bonus-reward-squares'>
          </img>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/bonusRewardAdventureExp.png?alt=media&token=63bd5655-6b53-4cf9-8c55-4c90d59d05fc"
            className='bonus-reward-squares'>
          </img>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/bonusRewardMora.png?alt=media&token=bf955e8f-7479-4c3b-a4f1-9155550c0d06"
            className='bonus-reward-squares'>
          </img>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/bonusRewardCompanionExp.png?alt=media&token=f126935f-c52f-441d-85b5-3a6329e664f4"
            className='bonus-reward-squares'>
          </img>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/bonusRewardAyaka.png?alt=media&token=7f3ba244-8904-42d6-892e-a86a5b2e70da"
            className='bonus-reward-squares'>
          </img>
        </div>
        <div className='commission-fraction'>
          <span key={`fraction-${numCompletedCommissions}`}>{numCompletedCommissions}/4</span>
        </div>
        <div className='commission-progress-bar'>
          {progressBarRender}
        </div>
        <Row className="action-bar-container">
          <Col xs={4} className="sign-in-box" onClick={
            async () => {
              if (!user) {
                await signInWithGoogle();
              } else {
                setShowSignOutModal(true);
              }
            }
          }>
            <Col>
              <img
                className="default-avatar"
                src={user ? user.photoURL : "https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/Character_Unknown_Thumb.png?alt=media&token=977d2203-ff20-4e30-bbf9-096a0aa5e93d"}
              ></img>
            </Col>
            <Col>
              <span className={user ? 'signed-in-name' : 'sign-in-text'}>{user ? user.displayName : "Sign In"}</span>
            </Col>
          </Col>
          <Col xs={3} className="add-commission-box" onClick={() => {
            user ? 
            setShowAddCommissionModal(true) : setShowPleaseSignInMessage(true);
          }}>
            <span className='add-commission-text'>Add New Commission</span>
          </Col>
          <Col xs={5} className="music-box">
            <img
              className='current-song-cover'x
              src={songCover}
            ></img>
            <div className='music-marquee'>
              <Marquee speed={30}>
                <span className='music-title'>{songName}&nbsp;•&nbsp;</span>
              </Marquee>
            </div>
            <div className='play-control' onClick={toggleAudio}>
              {isSongPlaying ? "Pause" : "Play"}
            </div>
            <div className='song-change' onClick={() => {setShowSongChangeModal(true)}}>
              Change
            </div>
          </Col>
        </Row>
        {
          numCompletedCommissions >= 4 ? 
          <img
            className='all-complete-filler'
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/allComplete.png?alt=media&token=80e52aec-4cc9-479f-af61-8879c0321f4b"
          >
          </img> : <></>
        }
        {
          showPleaseSignInMessage ?
          <>
          <div className='overlay' onClick={() => {setShowPleaseSignInMessage(false)}}></div>
          <div className="please-sign-in-modal">
            <img
            className='plead-message-icon'
            src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/13-1.png?alt=media&token=43eb8a28-5a17-441f-8548-5f65ec068062">
            </img>
            <span className='plead-message'>Please sign in to add and view your own commissions!<br></br><span className='small-plead'>{`(click anywhere in the blank area to close)`}</span></span>
          </div>
          </> : <></>
        }
        {
          showSongChangeModal ?
          <>
          <div className='overlay' onClick={() => {setShowSongChangeModal(false)}}></div>
          <div className='song-change-modal'>
              <span></span>
              <span className='song-modal-title'>Choose a Song!</span>
              <span></span>
              {songGalleryArray}
          </div>
          </> : <></>
        }
        {
          showCommissionDetails ? 
          <>
          <div className='blue-overlay' onClick={() => {setShowCommissionDetails(false); setCommisionsDetailsError(null);;}}></div>
          <div className='commission-details-modal'>
            <img
              className='commission--details-base-image'
              src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/base%20commission%20details%20blank.jpeg?alt=media&token=1e8dd77e-9dc4-4d8e-9faa-a26ae8826d42"
            >
            </img>
            <img className="commission-details-image" src={user.photoURL}></img>
            {commisionDetailsTitle.length > 80 ? 
            <span className="commission-details-title long-title">{commisionDetailsTitle}</span> : <span className="commission-details-title">{commisionDetailsTitle}</span>}
            <div className='main-details-content'>
              <Container>
                <Row className="main-details-content-header">Commission Report</Row>
                <Row>{commissionDetailsNotes}</Row>
              </Container>
            </div>
            {commissionDetailsError ? <span className='commision-details-error'>{commissionDetailsError}</span> : <></>}
            <Row className="commission-details-buttons">
              <Col className='commission-details-button abandon-button' style={{marginLeft: '5%', marginRight: '1%'}} onClick={async () => {
                const res = await abandonCommission(commissionDetailsId);
                if (res.status) {
                  checkUserStatus();
                  setShowCommissionDetails(false);
                  setCommissionDetailsId("");
                  setCommissionDetailsNotes("");
                  setCommissionDetailsTitle("");
                  setCommisionsDetailsError(null);
                } else {
                  setCommisionsDetailsError(res.message)
                }
              }}>
                Abandon Commission
              </Col>
              <Col className='commission-details-button complete-button' style={{marginRight: '5%', marginLeft: '1%'}} onClick={async () => {
                const res = await completeCommission(commissionDetailsId);
                if (res.status) {
                  checkUserStatus();
                  setShowCommissionDetails(false);
                  setCommissionDetailsId("");
                  setCommissionDetailsNotes("");
                  setCommissionDetailsTitle("");
                  setCommisionsDetailsError(null);
                } else {
                  setCommisionsDetailsError(res.message);
                }
              }}>
                Complete Commission
              </Col>
            </Row>
          </div></> :<></>
        }
        {
          showSignOutModal ?
          <>
          <div className='overlay' onClick={() => {setShowSignOutModal(false)}}></div>
          <div className='sign-out-modal'>
            <Container>
              <Row className='sign-out-header'>Are you sure you want to sign out?</Row>
              <Row className='modal-button-row'>
                <Col className='confirm-button' onClick={() => {
                  auth.signOut();
                  resetStates();
                  setShowSignOutModal(false);
                  }}>Confirm</Col>
                <Col className='cancel-button' onClick={() => {
                  setShowSignOutModal(false)
                }}>Cancel</Col>
              </Row>
            </Container>
          </div></> : <></>
        }
        {
          showAddCommissionModal ?
          <>
          <div className='overlay' onClick={() => {setShowAddCommissionModal(false); setModalErrorMessage(null);}}></div>
          <div className="add-commission-modal">
            <img
              className="katheryne-pic"
              src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/Katheryne_Support_Card.png?alt=media&token=e3469bbf-9726-43a2-945b-131b85a36992"
            ></img>
            <Container>
            <Row>
              <span className='add-commission-header'>Ad Astra Abyssosque! Welcome to the Adventurer's Guild. Would you like to post a new commission?</span>
            </Row>
            <Row style={{marginTop: '2%'}}>
              <span style={{fontSize: '1.3vw'}}>Commission Title*:</span>
            </Row>
            <Row style={{marginLeft: '1%'}}>
              <input type="text" id="new-commission-title" name="new-commission-title" className='new-commission-title-field'></input>
            </Row> 
            <Row>
              <span style={{fontSize: '1.3vw', marginTop: '2%'}}>Commission Notes:</span>
            </Row>
            <Row style={{marginLeft: '1%'}}>
              <textarea rows="3" cols="50" name="comment" classname="new-commission-notes" id="new-commission-notes">
                </textarea>
            </Row>
            <Row style={{marginLeft: '1%', marginTop: '5%'}}>
              <Col className='commission-modal-button commission-modal-create-button' onClick={async () => {
                const result = await addNewCommission();
                if (result.status) {
                  checkUserStatus();
                  setShowAddCommissionModal(false);
                  setModalErrorMessage(null);
                } else {
                  setModalErrorMessage(result.message)
                }
              }}>
                CREATE
              </Col>
              <Col className='commission-modal-button commission-modal-cancel-button' onClick={() => {
                setShowAddCommissionModal(false);
                setModalErrorMessage(null);
              }}>
                CANCEL
              </Col>
            </Row>
            {
              modalErrorMessage ? <Row style={{marginTop: '2%'}}>
                <span className='modal-error-message'>ERROR: {modalErrorMessage}</span>
              </Row> : <></>
            }
            </Container>
          </div></>
          : <></>
        }
        {
          showRegionDropdown ?  
          <div className="region-dropdown-menu">
            <Row className="dropdown-option">
              <span className="dropdown-text" onClick={
                async () => {
                  setSelectedRegion('Monstadt');
                  if (existingUserRef) {
                    const db = getFirestore();
                    try {
                      await updateDoc(doc(db, 'users', existingUserRef), {
                        region: 'Mondstadt'
                      });
                    } catch (err) {
                      console.log(err)
                    }
                  }
                  setShowRegionDropdown(false);
                  }
                }>Mondstadt</span>
            </Row>
            <Row className="dropdown-option">
              <span className="dropdown-text" onClick={
                async () => {
                  setSelectedRegion('Liyue');
                  if (existingUserRef) {
                    const db = getFirestore();
                    try {
                      await updateDoc(doc(db, 'users', existingUserRef), {
                        region: 'Liyue'
                      });
                    } catch (err) {
                      console.log(err)
                    }
                  }
                  setShowRegionDropdown(false);
                  }
                }>Liyue</span>
            </Row>
            <Row className="dropdown-option">
              <span className="dropdown-text" onClick={
                async () => {
                  setSelectedRegion('Inazuma');
                  if (existingUserRef) {
                    const db = getFirestore();
                    try {
                      await updateDoc(doc(db, 'users', existingUserRef), {
                        region: 'Inazuma'
                      });
                    } catch (err) {
                      console.log(err)
                    }
                  }
                  setShowRegionDropdown(false);
                  }
                }>Inazuma</span>
            </Row>
            <Row className="dropdown-option">
              <span className="dropdown-text" onClick={
                async () => {
                  setSelectedRegion('Sumeru');
                  if (existingUserRef) {
                    const db = getFirestore();
                    try {
                      await updateDoc(doc(db, 'users', existingUserRef), {
                        region: 'Sumeru'
                      });
                    } catch (err) {
                      console.log(err)
                    }
                  }
                  setShowRegionDropdown(false);
                  }
                }>Sumeru</span>
            </Row>
            <Row className="dropdown-option">
              <span className="dropdown-text" onClick={
                async () => {
                  setSelectedRegion('Fontaine');
                  if (existingUserRef) {
                    const db = getFirestore();
                    try {
                      await updateDoc(doc(db, 'users', existingUserRef), {
                        region: 'Fontaine'
                      });
                    } catch (err) {
                      console.log(err)
                    }
                  }
                  setShowRegionDropdown(false);
                  }
                }>Fontaine</span>
            </Row>
          </div> : <></>
        }
      </div>
      <span className='credit-tag'>
        A fun little project by <a className="twitter-link" href="https://twitter.com/BocchiTheWok">@BocchiTheWok</a>
      </span>
    </div>
  );
}

export default App;
