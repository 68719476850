import { Row, Col } from "react-bootstrap";
import "./Commission.css";
import Marquee from "react-fast-marquee";

function Commission({id, title, notes, setShowCommissionDetails, setCommissionDetailsTitle, setCommissionDetailsNotes, setCommissionDetailsId}) {
    return (
        <Row className="commission-container" onClick={() => {

            setShowCommissionDetails(true);
            setCommissionDetailsTitle(title);
            setCommissionDetailsNotes(notes);
            setCommissionDetailsId(id);
        }}>
            <Col xs={1}>
                <img
                    className="commission-icon"
                    src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/Icon_Commission.png?alt=media&token=5a579775-100d-4e6c-bab1-9b1739b471b3">
                </img>
            </Col>
            <Col xs={5} className="commision-title-wrapper" style={{whiteSpace: "nowrap", overflowX: "auto"}}>
                {title.length > 20 ? <Marquee><span className="commission-text">{title}&nbsp;•&nbsp;</span></Marquee> : <span className="commission-text">{title}</span>}
            </Col>
            <Col xs={5} className="reward-columns">
                <img
                    className="reward-icon"
                    src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/PrimoReward.png?alt=media&token=f56d55cd-e5e5-478e-95ac-c9d1dc13c2cb"
                >
                </img>
                <img
                    className="reward-icon"
                    src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/EXPReward.png?alt=media&token=ec7f2bb2-90f4-4e16-9a75-57271ef7a37f"
                >
                </img>
                <img
                    className="reward-icon"
                    src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/MoraReward.png?alt=media&token=adcc17ce-5bda-4265-8517-fe8950dbdee6"
                >
                </img>
                <img
                    className="reward-icon"
                    src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/CompanionXPReward.png?alt=media&token=5d3f0512-81e1-4adf-bc32-66f90eae7b01"
                >
                </img>
                <img
                    className="reward-icon"
                    src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/PrimoReward.png?alt=media&token=f56d55cd-e5e5-478e-95ac-c9d1dc13c2cb"
                >
                </img>
            </Col>
            <Col xs={1} style={{padding: 0, justifyContent: 'right'}}>
                <img
                    className="check-column"
                    src="https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/UncheckMark.png?alt=media&token=6c629c84-22b8-461a-9968-b7f731c63d6f"></img>
            </Col>
        </Row>
    )
}

export default Commission;