const musicList = [
    {
        name: 'Bustling Afternoon in Monstadt',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/dvalin.jpeg?alt=media&token=63c19236-de27-401d-8c31-be6c251994fe',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FBustling%20Afternoon%20in%20Mondstadt.mp3?alt=media&token=3bc33e0c-2d7d-42d3-98c6-65a039175ed1',
    },
    {
        name: 'Dawn Winery',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/dvalin.jpeg?alt=media&token=63c19236-de27-401d-8c31-be6c251994fe',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FDawn%20Winery%20Theme.mp3?alt=media&token=cb178b44-d562-4f3d-b122-1d199b7e214d'
    },
    {
        name: "Forsaken Child of Ancient Times",
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/dvalin.jpeg?alt=media&token=63c19236-de27-401d-8c31-be6c251994fe',
        path: "https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FForsaken%20Child%20of%20Ancient%20Times%20(Dvalin's%20Nest).mp3?alt=media&token=5a516855-ec80-4cc2-819b-d54896d42a10"
    },
    {
        name: "Maiden's Longing",
        cover: "https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/maidens%20longing.jpeg?alt=media&token=f689ad5b-2367-4fd5-9a76-1525f3756254",
        path: "https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FMaiden's%20Longing.mp3?alt=media&token=209ebab6-914a-4c2f-b478-fd7f55c88c10"
    },
    {
        name: 'Peaceful Hike (Qingce Daytime)',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/maidens%20longing.jpeg?alt=media&token=f689ad5b-2367-4fd5-9a76-1525f3756254',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FPeaceful%20Hike%20(Qingce%20Daytime).mp3?alt=media&token=c0021981-9181-455c-9998-54ec49f41d7d'
    },
    {
        name: 'Liyue',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/maidens%20longing.jpeg?alt=media&token=f689ad5b-2367-4fd5-9a76-1525f3756254',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FLiyue.mp3?alt=media&token=80bdd363-acd7-40fe-9620-9d8f3f1e4a05'
    },
    {
        name: 'A Harmonious Rest',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/rest.jpeg?alt=media&token=70180d14-9488-4365-82ad-149690fce3dd',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FA%20Harmonious%20Rest.mp3?alt=media&token=16b38a76-25df-45df-a0ea-565dab66d85c'
    },
    {
        name: 'Separated Dream',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/rest.jpeg?alt=media&token=70180d14-9488-4365-82ad-149690fce3dd',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FSeparated%20Dream.mp3?alt=media&token=6e44c82a-51f1-4831-981f-de0f95d354ac'
    },
    {
        name: 'Streets of Elegance',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/rest.jpeg?alt=media&token=70180d14-9488-4365-82ad-149690fce3dd',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FStreets%20of%20Elegance.mp3?alt=media&token=dba943e7-afd2-4f05-be92-ddfc179efbb1'
    },
    {
        name: 'Hustle and Bustle of Ormos',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/Sumeru.jpeg?alt=media&token=81fa242c-adcf-43f8-bb54-2fa0650a3536',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FHustle%20and%20Bustle%20of%20Ormos.mp3?alt=media&token=cf7f256c-f7fb-4ee2-b77e-1e7272e77e56'
    },
    {
        name: 'For Riddles, for Wonders',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/Sumeru.jpeg?alt=media&token=81fa242c-adcf-43f8-bb54-2fa0650a3536',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FFor%20Riddles%2C%20for%20Wonders.mp3?alt=media&token=892d51a7-5404-4abe-9a94-66a01aa16c8d'
    },
    {
        name: 'God Devouring Mania',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/desert.jpeg?alt=media&token=02d925ed-ef9d-4c6b-9d03-d2b1445b6c4c',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FGod-Devouring%20Mania.mp3?alt=media&token=aff5be6c-9266-468f-8483-68bac35bcbb1'
    },
    {
        name: 'Le spectacle doit continuer',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/cover.jpeg?alt=media&token=476b847b-7f7c-41fc-a8fa-3ef38bbdf6c1',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FLe%20spectacle%20doit%20continuer.mp3?alt=media&token=fc00ac96-d6d7-4a45-9c6f-108906126c75'
    },
    {
        name: 'Les murmures des flots',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/cover.jpeg?alt=media&token=476b847b-7f7c-41fc-a8fa-3ef38bbdf6c1',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FLes%20murmures%20des%20flots.mp3?alt=media&token=7095b814-6800-4229-9f45-12f695d7095a'
    },
    {
        name: 'City of Mellifluous Glory',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/cover.jpeg?alt=media&token=476b847b-7f7c-41fc-a8fa-3ef38bbdf6c1',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FCity%20of%20Mellifluous%20Glory.mp3?alt=media&token=fa9b9c96-4708-45b7-a202-51caa105691e'
    },
    {
        name: "Qilin's Prance",
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/ganyu.jpeg?alt=media&token=02c2a289-e422-4f4a-9b62-521c44db84a2',
        path: "https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FQilin's%20Prance.mp3?alt=media&token=63bd509c-102a-4904-9b2f-42a51e4d9c38"
    },
    {
        name: 'Rage Beneath the Mountains',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/rage.jpeg?alt=media&token=fd118647-2143-4124-812b-ac7cf065d2a3',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FRage%20Beneath%20the%20Mountains.mp3?alt=media&token=bc7f928e-9278-49f1-849d-d07e5a63b8bc'
    },
    {
        name: 'Graceful Dexterity (Yelans Theme)',
        cover: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/cover2.jpeg?alt=media&token=18a5d966-f825-423e-a276-9e2ce8820286',
        path: 'https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/music%2FGraceful%20Dexterity.mp3?alt=media&token=b90b0113-4d4a-4bbd-9bad-fe13a69682cd'
    },
];

export default musicList;